<template>
  <div :id="`modal-${this.mediaClassification}`" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
          <h4 id="modalAddLayerTitle" class="modal-title">{{ modalTitle }}</h4>
        </div>
        <div class="modal-body">
          <input id="file" ref="file" type="file" :accept="this.acceptedFormats" multiple @change="handleFileUpload" />
          <div
            class="dropzone"
            :class="{ draggedOver: dragOver }"
            @drop="dropHandler"
            @dragover="dragOverHandler"
            @dragexit="dragOutHandler"
            @dragleave="dragOutHandler"
            @dragend="dragOutHandler"
            @click="openFilePicker" >
            <span v-if="files.length < 1" class="glyphicons glyphicons-cloud-upload dropzoneIcon"></span>
            <span v-if="files.length < 1" class="fileName">{{ $t("label.DragAndDrop") }}</span>
            <span v-if="files.length > 0 && !uploading" class="glyphicons dropzoneIcon" :class="uploadedIcon"></span>
            <span v-if="uploading" class="glyphicons glyphicons-refresh dropzoneIcon spinning"></span>
            <span v-if="files.length === 1" class="fileName">{{ files[0].name }}</span>
            <span v-if="files.length > 1" class="fileName">{{ $t("label.multipleFiles", {fileCount: files.length}) }}</span>
          </div>
          <p v-if="!valid" class="error">{{ errorMessage }}</p>
        </div>
        <div class="modal-footer">
          <span v-if="!uploading" class="maximumUploadWarning">{{ $t("label.maxFileSizeWarning")}}</span>
          <button v-if="!uploading" id="btnAddFile" class="btn" v-bind:class="{ disabled: !valid }" @click="upload">
            {{ $t("label.add") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import config from '@/configurations/config.js'
import stringUtils from '@/utils/stringUtils'

import { MediaService } from '@/services/MediaWS/mediaService'
const mediaService = new MediaService()

export default {
  name: 'uploadModal',
  props: {
    option: {
      type: Object,
      required: true,
      default: () => {
        return { uploadType: '' } // Supported types: images, MapLayers}
      }
    },
    projectGuid: {
      type: String,
      required: false,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      errorMessage: null,
      valid: false,
      dragOver: false,
      uploading: false,
      files: [],
      maxFileByteSize: 20_000_000,
      maxMapLayerFileSize: 200_000_000,
      maxFileCount: 20,
      zipExtensions: ['zip', 'klip']
    }
  },
  computed: {
    parsedProjectGuid () {
      if (this.projectGuid && this.projectGuid !== '') return this.projectGuid
      if (!this.$store.state.workingObject || !this.$store.state.workingObject.PrGuid) {
        console.error('No project guid found, certain elements may not work properly')
        return ''
      }
      return this.$store.state.workingObject.PrGuid
    },
    mediaClassifications () {
      return config.constants.MediaClassification || {}
    },
    mediaClassification () {
      return this.option.uploadType
    },
    supportedTypes () {
      switch (this.mediaClassification) {
        case this.mediaClassifications.ProjectPhoto:
          return ['png', 'jpg', 'jpeg']
        case this.mediaClassifications.MapImages:
          return ['png', 'jpg', 'jpeg', 'pdf']
        case this.mediaClassifications.MapLayers:
          return ['kml', 'zip', 'klip']
        case this.mediaClassifications.LabCertificates:
          return ['pdf']
        case this.mediaClassifications.ProjectDocuments:
          return ['pdf', 'xlsx', 'xlsm', 'xls', 'doc', 'docm', 'docx', 'dotm', 'txt', 'tif', 'tiff', 'msg', 'dxf', 'dwg', 'shp']
        default:
          return []
      }
    },
    modalTitle () {
      switch (this.mediaClassification) {
        case this.mediaClassifications.ProjectPhoto:
          return this.$t('label.AddPictures')
        case this.mediaClassifications.MapImages:
          return this.$t('label.AddPictures')
        case this.mediaClassifications.MapLayers:
          return this.$t('label.AddMapLayer')
        case this.mediaClassifications.ProjectDocuments:
          return this.$t('label.AddProjectDocuments')
        case this.mediaClassifications.LabCertificates:
          return this.$t('label.AddLabCertificates')
        default:
          return ''
      }
    },
    uploadedIcon () {
      switch (this.mediaClassification) {
        case this.mediaClassifications.ProjectPhoto:
        case this.mediaClassifications.MapImages:
          return 'glyphicons-picture'
        case this.mediaClassifications.MapLayers:
          return 'glyphicons-cadastral-map'
        case this.mediaClassifications.ProjectDocuments:
        case this.mediaClassifications.LabCertificates:
          return 'glyphicons-file'
        default:
          return ''
      }
    },
    acceptedFormats () {
      let supportedTypes = this.supportedTypes.toString()
      let acceptedFormats = `.${supportedTypes}`
      return acceptedFormats.replaceAll(',', ',.')
    }
  },
  methods: {
    setErrorMessage(errorMessage) {
      this.uploading = false
      this.valid = false
      this.files = []
      this.errorMessage = errorMessage
    },
    dragOverHandler (e) {
      e.preventDefault()
      this.dragOver = true
    },
    checkZipExtends(fileName) {
      return this.zipExtensions.includes(stringUtils.getFileExtension(fileName).toLowerCase())
    },
    dragOutHandler (e) {
      this.dragOver = false
    },
    dropHandler (e) {
      e.preventDefault()
      this.files = []
      this.uploadMultipleFiles(e.dataTransfer.files)
      this.dragOver = false
    },
    openFilePicker () {
      if (this.uploading) return
      this.$refs.file.click()
    },
    handleFileUpload (event) {
      this.files = []
      this.uploadMultipleFiles(event.target.files)
    },
    uploadMultipleFiles(files) {
      this.valid = true
      if (files.length > this.maxFileCount) {
        this.setErrorMessage(this.$t('label.maxFileCountError', { fileCountLimit: this.maxFileCount }))
        return
      }
      let countZipKlicFiles = 0
      let totalFileSize = 0

      for (let i = 0; i < files.length; i += 1) {
        let fileExtension = stringUtils.getFileExtension(files[i].name).toLowerCase()
        if (!this.supportedTypes.includes(fileExtension)) {
          this.setErrorMessage(this.$t('label.invalidFileExtension') + ': ' + fileExtension)
          return
        }
        if (this.checkZipExtends(files[i].name)) {
          countZipKlicFiles += 1
          if (countZipKlicFiles > 1) {
            this.setErrorMessage(this.$t('label.maxZipCountError'))
            return
          }
        }
        if (files[i].size > this.maxFileByteSize && this.mediaClassification !== this.mediaClassifications.MapLayers) {
          this.setErrorMessage(this.$t('label.fileSizeError', { sizeLimit: (this.maxFileByteSize / 1_000_000) }))
          return
        }
        totalFileSize += files[i].size
      }
      if (totalFileSize > this.maxMapLayerFileSize) {
        this.setErrorMessage(this.$t('label.fileSizeError', { sizeLimit: (this.maxMapLayerFileSize / 1_000_000) }))
        return
      }

      this.files = files
    },
    shouldReadFromData () {
      return this.mediaClassification === this.mediaClassifications.ProjectPhoto ||
      this.mediaClassification === this.mediaClassifications.ProjectDocuments ||
      this.mediaClassification === this.mediaClassifications.MapImages
    },
    async uploadZipFile(fileName, prGuid) {
      var reader = new FileReader()
      reader.readAsDataURL(this.files[0])
      reader.onload = evt => {
        let result = evt.target.result
        result = result.split(',').last()
        mediaService.uploadMedia(prGuid, fileName, result, this.mediaClassification).then(async response => {
          await this.handleResponse(response, prGuid)
        })
      }

      reader.onerror = evt => {
        this.setErrorMessage(this.$t('label.fileContentError'))
      }
    },
    async upload () {
      this.uploading = true
      const prGuid = this.parsedProjectGuid
      if (this.files.length === 1 && this.checkZipExtends(this.files[0].name)) {
        if (this.files[0].size > this.maxMapLayerFileSize) {
          this.setErrorMessage(this.$t('label.fileSizeError', { sizeLimit: (this.maxMapLayerFileSize / 1_000_000) }))
          return
        }
        this.uploadZipFile(this.files[0].name, prGuid)
        return
      }
      const response = await mediaService.sessionUpload(prGuid, this.files, this.mediaClassification)
      await this.handleResponse(response, prGuid)
    },
    async handleProjectMediaListResponse (prGuid, response) {
      if (this.mediaClassification === this.mediaClassifications.ProjectPhoto) {
        this.$store.commit('setPictures', response.data.files)
      } else if (this.mediaClassification === this.mediaClassifications.ProjectDocuments) {
        this.$store.commit('setProjectDocuments', response.data.files)
      } else if (this.mediaClassification === this.mediaClassifications.MapImages) {
        this.$store.commit('setMapImages', response.data.files)
      } else if (this.mediaClassification === this.mediaClassifications.LabCertificates) {
        this.$store.commit('setLabCertificates', response.data.files)
      } else {
        this.$store.commit('setMapLayers', response.data.files)
        // When uploading a zip/klic file it could potentially contain pdfs, and therefore the Project Document box needs to be uploaded
        const projectDocumentResponse = await this.$store.dispatch('getProjectMediaList', {
          mediaType: this.mediaClassifications.ProjectDocuments
        })
        this.$store.commit('setProjectDocuments', projectDocumentResponse.data.files)
        // The same goes for the KLIC files box
        const klicFilesResponse = await this.$store.dispatch('getProjectMediaList', {
          mediaType: this.mediaClassifications.KLICFiles
        })
        this.$store.commit('setKlicFiles', klicFilesResponse.data.files)
      }
    },
    async handleResponse(response, prGuid) {
      if (response.status === 200) {
        if (response.data.ResultCode !== 'Upload_Succes') {
          console.error('Something went wrong on uploading file. Resultcode: ', response.data.ResultCode)
          this.setErrorMessage(this.$t('label.fileContentError'))
          return
        }

        const projectMediaListResponse = await this.$store.dispatch('getProjectMediaList', {
          mediaType: this.mediaClassification
        })
        this.handleProjectMediaListResponse(prGuid, projectMediaListResponse)

        $(`#modal-${this.mediaClassification}`).modal('hide')
        this.uploading = false
        this.files = []
        this.$emit('uploaded', response)
      } else {
        if (response.status === 413) {
          this.setErrorMessage(this.$t('label.fileSizeError', { sizeLimit: (this.maxFileByteSize / 1_000_000) }))
          return
        }
        this.uploading = false
        console.error('Something went wrong. Response status: ', response.status)
      }
    }
  }
}
</script>

<style scoped lang="less">
.dropzone {
  margin-top: 1rem;
  display: block;
  content: "";
  /*border: dashed 2px #333;*/
  text-align: center;
  cursor: pointer;
  width: 100%;

  .dropzoneIcon {
    font-size: 10rem;
    color: #333;
    display: block;
  }

  .fileName {
    display: block;
  }

  .spinning {
    animation: completeSpin 1.2s linear infinite;
  }

  @keyframes completeSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
#file {
  display: none;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .maximumUploadWarning {
    margin-right: auto;
    color: @warning600;
  }
  .btn {
    margin-left: auto;
  }
}
.draggedOver {
  .dropzoneIcon {
    color: #67ac45;
    display: block;
  }
}
.error {
  text-align: center;
}
</style>
